import React, { FunctionComponent, useEffect, useRef, useState } from "react"
import "../assets/styles/page/doclandingpage.scss"
import RichTextRenderer from "../components/rich-text-handler/rich-text-handler"
import Layout from "../components/layout"
import Facebookshare from "../components/facebookshare"
import Tweetbutton from "../components/tweetbutton"

const Landingnoticepage: FunctionComponent = pageContext => {
  const { landingNoticeMeta, landingNoticeSubtext, landingNoticeTitle, landingBanner, landingNoticeBody } =
    pageContext?.pageContext.edge
  const imageRef: any = useRef(null)
  const [height, setHeight] = useState(676.248)

  useEffect(() => {
    let timeout: NodeJS.Timeout

    // handles setting the iframe's height on window resize to maintain aspect ratio
    const updateSize = () => {
      if (!imageRef?.current) return

      clearTimeout(timeout)

      timeout = setTimeout(() => {
        setHeight(imageRef?.current?.clientWidth / (16 / 9))
      }, 100)
    }

    updateSize()

    window.addEventListener("resize", updateSize)
    return () => window.removeEventListener("resize", updateSize)
  }, [imageRef])

  return (
    <div className="landing--notice--wrapper">
      <Layout>
        <div className="landing--content--inner">
          <div className="landing--banner--wrapper">
            <div
              className="banner--image--wrapper"
              style={{
                backgroundImage: `url(${landingBanner.fluid.src})`,
                height: height
              }}
              ref={imageRef}
            ></div>
            <div className="banner--overlap--wrapper">
              <div className="banner--overlap--text--inner">
                <h2 className="banner--overlap--headtext">{landingNoticeTitle}</h2>
                <p className="banner--overlap--subtext">{landingNoticeSubtext}</p>
                <p className="meta">{landingNoticeMeta}</p>
              </div>
            </div>
          </div>
          <div className="landing--main--content">
            <RichTextRenderer content={landingNoticeBody.json} />
          </div>
          <div className="share--article--media">
            <Facebookshare />
            <Tweetbutton titleOfArticle={landingNoticeTitle} />
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default Landingnoticepage
